
.animateY {
    animation: MoveUpDown 8s linear infinite;
    position: absolute;
    left: 0;
    bottom: 180px;
  }
  
@keyframes MoveUpDown {
0%, 100% {
    transform: translateY(0);
}
50% {
    transform: translateY(20px);
}
}

.stone1 {
    animation: stone1Anim 30s ease-in-out infinite;
    animation-fill-mode: both;
    animation-delay: 1.5s;
}

@keyframes stone1Anim {
    0% {
      transform: rotate(0deg) ;
    }
    50% {
    
      transform:rotate(41deg) translateX(3rem) translateY(-4rem);
    }
    100% {
        transform: rotate(0deg) ;
    }
}

.stone2 {
    animation: stone2Anim 20s ease-in-out infinite;
    animation-fill-mode: both;
    animation-delay: 2s;
}
.stone3 {
    animation: stone3Anim 20s ease-in-out infinite;
    animation-fill-mode: both;
    animation-delay: 1s;
}
.stone4 {
    animation: stone4Anim 20s ease-in-out infinite;
    animation-fill-mode: both;
    animation-delay: 3s;
}
.stone5 {
    animation: stone5Anim 20s ease-in-out infinite;
    animation-fill-mode: both;
}


@keyframes stone2Anim {
    0% {
      transform: rotate(0deg) translateX(0%) translateY(0%);
    }
    50% {
    
      transform: rotate(20deg) translateX(-2rem) translateY(3rem);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes stone3Anim {
    0% {
      transform: rotate(0deg);
    }
    50% {
    
      transform: rotate(20deg) translateX(30%) translateY(-40%);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes stone4Anim {
    0% {
    }
    50% {
    
      transform: rotate(30deg) translateX(-20%) translateY(20%);
    }
    100% {
        transform: rotate(0deg) translateX(0%) translateY(0%);
    }
}
@keyframes stone5Anim {
    0% {
      
    }
    50% {
    
      transform: rotate(-20deg) translateX(-40%) translateY(30%);
    }
    100% {
        transform: rotate(0deg) translateX(0%) translateY(0%);
    }
}


.discoverShadow {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.35), inset 0px 1px 5px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    transition: 0.2s ease-in;
}

.discoverShadow:hover {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.35), inset 0px 1px 0px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
}

.headerText {
        position: relative;
        display: inline-block;
        overflow: hidden;
        background: linear-gradient(to right, #006D92, #006D92 50%, white 50%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 100%;
        background-position: 100%;
        transition: background-position 400ms ease;
}

.headerText:hover {
    background-position: 0 100%;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

