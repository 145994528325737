.placeBid {
    background: linear-gradient(108.53deg, #05F8ED 0.96%, #981E6E 101.51%);
    border-radius: 50px;
}

.blurB {
    background: linear-gradient(147.74deg, rgba(29, 29, 29, 0.352) -19.65%, rgba(19, 15, 64, 0.648) 108.09%);
backdrop-filter: blur(7.5px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 0px 0px 25px 25px;
}