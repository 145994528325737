
.utilityBox {
    background: linear-gradient(215.45deg, #0E0067 0%, #2C236B 100%);
    box-shadow: 0px 8px 10px rgba(0, 255, 243, 0.25);
    border-radius: 0px 0px 50px 50px;
}

.utilityBox:hover {
    transform: translateY(-1%);
    box-shadow: 0px 12px 10px rgba(0, 255, 243, 0.25);
    transition: 0.2s ease-in-out;
}
