.carousel {
    margin: 0 auto;
    overflow: hidden;
    /* max-width: 900px; */
    position: relative;
    width: 276px;
}

.carousel-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: ease 1s;
}

.carousel-item {
    display: inline-block;
    width: 276px;
}

.carousel-control {
    background-color: rgba(0,0,0,0.5);
    border: none;
    display: inline-block;
    position: absolute;
    height: 50px;
    width: 70px;
    top: calc(50% - 25px);
    cursor: pointer;
    color: #f3f3f3;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.carousel-indicators {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: -5px;
    z-index: 2;
}

.carousel-indicator-item {
    width: 8px;
    height: 8px;
    border: none;
    background: rgba(255, 255, 255, 0.3);
    opacity: 0.5;
    margin: 0.2em;
    border-radius: 50%;
    cursor: pointer;
}

.active {
    opacity: 1;
}
