@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ROBOTECH';
  src: local('ROBOTECH'), url(./fonts/ROBOTECH\ GP.ttf) format('truetype');
}

*, html {
  scroll-behavior: smooth !important;
  

}

html:focus-within {
  scroll-behavior: smooth !important;

}

video::-webkit-media-controls-panel {
  display: none;
  -webkit-appearance: none;
}
video::-webkit-media-controls-play-button {
  display: none;
  -webkit-appearance: none;
}
video::-webkit-media-controls-start-playback-button {
  display: none;
  -webkit-appearance: none
}

video::-webkit-media-controls {
  display: none;
  -webkit-appearance: none;
}