

.cutCorners {
    -webkit-clip-path: polygon(6% 0, 94% 0, 100% 6%, 100% 94%, 94% 100%, 6% 100%, 0 94%, 0 6%);
  clip-path:  polygon(6% 0, 94% 0, 100% 6%, 100% 94%, 94% 100%, 6% 100%, 0 94%, 0 6%);
  
}

.diamondAnim {

  animation: MoveUpDownTeam 5s linear infinite;
}
.diamondAnim2 {

  animation: MoveUpDownTeam2 8s linear infinite;
}

@keyframes MoveUpDownTeam {
  0%, 100% {
    bottom: -80;
  }
  50% {
    bottom: -110px;
  }
}

@keyframes MoveUpDownTeam2 {
  0%, 100% {
    bottom: -80;
  }
  50% {
    bottom: -85px;
  }
}