

.logoText1 {
    font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 46px;
/* identical to box height */

letter-spacing: 0.15em;

/* zayıfIşık */

background: linear-gradient(90deg, #3db53f 0%, #1cf9ea 51.35%, #8d0f63 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
}


.logoText2 {

/* identical to box height */


/* zayıfIşık */
font-weight: 400;
background: linear-gradient(90deg, #4dc6f2 0%, #e63cd2 51.35%, #f21483 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
}


.gameDetails {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 57px;
    text-align: center;
    letter-spacing: 0.15em;

    color: #FFFFFF;

    text-shadow: 0px 10px 10px #000000;
}